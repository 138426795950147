import { DzColumn } from '@zwirner/design-system'

import { BooksSubnav } from '@/components/containers/books/booksSubnav'
import { FullWidthFlexCol } from '@/components/containers/layout/FullWidthFlexCol'
import PageBuilder from '@/components/pageBuilder'
import { DzTitlePage } from '@/components/wrappers/DzTitlePageWrapper'
import type { PageType } from '@/sanity/queries/page/page'

type Props = {
  data: PageType
}

export const BooksAboutContainer = ({ data }: Props) => {
  const { title, content } = data

  return (
    <DzColumn span={12}>
      <BooksSubnav />
      <DzTitlePage title={title} isWide />

      <FullWidthFlexCol>{content ? <PageBuilder components={content} /> : null}</FullWidthFlexCol>
    </DzColumn>
  )
}
