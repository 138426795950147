import { groq } from 'next-sanity'
import { z } from 'zod'

import { ctaBuilder, CTABuilderSchema } from '../../components/builders/ctaBuilder'
import { pageSEOFields, PageSEOFieldsExtendedSchema } from '../../components/seo/pageSEOFields'
import {
  pageBuilderComponentsData,
  PageBuilderComponentsDataSchema,
} from './pageBuilderComponentsData'

export const pageComplexFields = groq`
content[] {
  ${pageBuilderComponentsData}
},
subnav {
  isActive,
  items[] {
    ${ctaBuilder}
  },
  cta {
    ${ctaBuilder}
  }
},
"seo": {
  title,
  'canonical': coalesce(slug.current, null),
  ...seo {
    ${pageSEOFields}
  }
}
`

export const PageComplexFieldsSchema = z.object({
  seo: PageSEOFieldsExtendedSchema,
  content: z.nullable(z.array(PageBuilderComponentsDataSchema)),
  subnav: z.object({
    isActive: z.boolean(),
    cta: z.nullable(CTABuilderSchema),
    items: z.nullable(z.array(CTABuilderSchema)),
  }),
})
