import type { SectionNavItem } from '@zwirner/design-system'
import { BUTTON_VARIANTS } from '@zwirner/design-system'

import { INQUIRE, NEWSLETTER } from '@/common/constants/commonCopies'
import { singleUnitMapper } from '@/common/utilsMappers/cta.mapper'
import type { CTABuilderType } from '@/sanity/queries/components/builders/ctaBuilder'
import { CTAActionTypes } from '@/sanity/types'

export const showCTAOption = ({ text, action }: CTABuilderType) => Boolean(text && action)

const getStatus = (action: CTAActionTypes | undefined) => {
  switch (action) {
    case CTAActionTypes.INQUIRE:
      return INQUIRE
    case CTAActionTypes.NEWSLETTER:
      return NEWSLETTER
    default:
      return null
  }
}

export const mapSubnavItems = (ctaItems: CTABuilderType[], path: string) => {
  const subnavCTA = ctaItems.map((cta) => {
    return singleUnitMapper({
      data: cta,
      options: {
        asLink: true,
      },
    })
  })

  return subnavCTA
    ?.map((item) => ({
      text: item?.text,
      url: item?.url,
      ...(item?.url && { active: path.startsWith(item.url) }),
    }))
    .filter((item) => item.text && item.url) as SectionNavItem[]
}

export const mapAdditionalCTA = (cta: CTABuilderType) => {
  const mappedCTA = singleUnitMapper({
    data: cta,
    options: {
      asLink: true,
      modalProps: createModalProps(cta),
    },
  })

  return {
    ...mappedCTA,
    text: mappedCTA?.text ?? '',
    ctaProps: {
      onClick: mappedCTA?.onClick,
      variant: BUTTON_VARIANTS.TERTIARY,
    },
  }
}

export const createModalProps = (cta: CTABuilderType | null) => {
  return {
    useAnchor: true,
    contextData: {
      status: getStatus(cta?.action),
      ctaText: cta?.text,
    },
  }
}
