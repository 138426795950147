import { groq } from 'next-sanity'
import { z } from 'zod'

import { pageSEOFields, PageSEOFieldsExtendedSchema } from '../components/seo/pageSEOFields'
import { pageSimpleFields, PageSimpleFieldsSchema } from './pageCommonQueries/pageSimpleFields'

export const staticPage = groq`
  staticContentUrl,
  ${pageSimpleFields}
  "seo": {
    title,
    'canonical': coalesce(slug.current, null),
    ...seo {
      ${pageSEOFields}
    }
  }
`

export const StaticPageSchema = z
  .object({
    staticContentUrl: z.string(),
    seo: PageSEOFieldsExtendedSchema,
  })
  .merge(PageSimpleFieldsSchema)
