import { DzColumn } from '@zwirner/design-system'
import { useRouter } from 'next/router'

import { FullWidthFlexCol } from '@/components/containers/layout/FullWidthFlexCol'
import PageBuilder from '@/components/pageBuilder'
import { DzSectionMenu } from '@/components/wrappers/DzSectionMenuWrapper'
import { DzTitlePage } from '@/components/wrappers/DzTitlePageWrapper'
import type { PageType } from '@/sanity/queries/page/page'

import { createModalProps, mapAdditionalCTA, mapSubnavItems, showCTAOption } from './mapper'
import { useOpenModalDispatch } from './useOpenModalDispatch'

type Props = {
  data: PageType
}

export const SpecialPagesContainer = ({ data }: Props) => {
  const { title, content, subnav } = data
  const { asPath } = useRouter()

  const sectionsCTA = mapSubnavItems(subnav?.items ?? [], asPath)
  const mappedCTA =
    subnav?.cta && showCTAOption(subnav.cta) ? mapAdditionalCTA(subnav.cta) : undefined

  const modalProps = createModalProps(subnav.cta)
  useOpenModalDispatch(modalProps)

  return (
    <DzColumn span={12}>
      {subnav && subnav?.isActive ? (
        <DzSectionMenu sections={sectionsCTA} cta={mappedCTA} prefix="" sticky usePrefix />
      ) : null}
      <DzTitlePage title={title} isWide />
      <FullWidthFlexCol>{content ? <PageBuilder components={content} /> : null}</FullWidthFlexCol>
    </DzColumn>
  )
}
