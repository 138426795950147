import { groq } from 'next-sanity'
import { z } from 'zod'

import { ctaBuilder } from '../components/builders/ctaBuilder'
import { pageSEOFields } from '../components/seo/pageSEOFields'
import { page, PageSchema } from './page'
import { pageBuilderDataTypes } from './pageCommonQueries/pageBuilderComponentsData'
import { pageSimpleFields } from './pageCommonQueries/pageSimpleFields'
import { staticPage, StaticPageSchema } from './staticPage'

export const pageBySlug = groq`
*[_type == "page" && defined(slug.current) && slug.current == $slug][0] {
  isStaticPage,
  isStaticPage == true => {
    ${staticPage}
  },
  isStaticPage == false => {
    ${page}
  }
}`

export const pageStaticDataBySlug = groq`
*[_type == "page" && defined(slug.current) && slug.current == $slug][0] {
  isStaticPage,
  ${staticPage}
}`

export const getPageDataBySlug = (content: string) => groq`
*[_type == "page" && defined(slug.current) && slug.current == $slug][0] {
  isStaticPage,
  ${pageSimpleFields}
  subnav {
    isActive,
    items[] {
      ${ctaBuilder}
    },
    cta {
      ${ctaBuilder}
    }
  },
  "seo": {
    title,
    'canonical': coalesce(slug.current, null),
    ...seo {
      ${pageSEOFields}
    }
  },
  ${content}
}`

export const pageStructure = groq`
{
  'isStaticPage': *[_type == "page" && defined(slug.current) && slug.current == $slug][0].isStaticPage,
  'structure': *[_type == "page" && defined(slug.current) && slug.current == $slug][0] {
    content[] {
      ${pageBuilderDataTypes}
    },
  }
}
`

export const PageBySlugPropsSchema = z.object({
  slug: z.string(),
})

export type PageBySlugPropsType = z.infer<typeof PageBySlugPropsSchema>

export const PageBySlugSchema = z.discriminatedUnion('isStaticPage', [
  StaticPageSchema.extend({ isStaticPage: z.literal(true) }),
  PageSchema.extend({ isStaticPage: z.literal(false) }),
])

export type PageBySlugType = z.infer<typeof PageBySlugSchema>
