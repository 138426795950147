import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { SUBSCRIBE_HASH_KEY } from '@/common/components/popups/modalTriggerListener/useNewsletterFormModal'
import { INQUIRE_HASH_KEY } from '@/components/hooks/useHashRoutedInquiryModal'
import { ModalTriggerEvent, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import { ModalTypes } from '@/sanity/types'

export const useOpenModalDispatch = (modalProps: Record<string, unknown>) => {
  const { asPath } = useRouter()

  useEffect(() => {
    if (asPath.includes(`#${INQUIRE_HASH_KEY}`)) {
      window.document.dispatchEvent(
        ModalTriggerEvent({
          modalType: ModalTypes.INQUIRE,
          props: modalProps,
          triggerType: ModalTriggerTypes.CTA,
        })
      )
    }
    if (asPath.includes(`#${SUBSCRIBE_HASH_KEY}`)) {
      window.document.dispatchEvent(
        ModalTriggerEvent({
          modalType: ModalTypes.NEWSLETTER,
          props: modalProps,
          triggerType: ModalTriggerTypes.CTA,
        })
      )
    }
  }, [asPath, modalProps])
}
