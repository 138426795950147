import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'

import DefaultLayout from '@/common/components/layout/Layout'
import { SEOComponent } from '@/common/components/seo/seo'
import { BOOKS_ABOUT_SLUG } from '@/common/constants/gtmPageConstants'
import { BooksAboutContainer } from '@/components/containers/books/booksAbout'
import PreviewPage from '@/components/containers/previews/pagePreview'
import StaticPagePreview from '@/components/containers/previews/staticPagePreview'
import { SpecialPagesContainer } from '@/components/containers/specialPages'
import { S3RootFolder } from '@/components/StaticPage/fetch'
import StaticPageContainer from '@/components/StaticPage/StaticPageContainer'
import StaticPageLayoutShadow from '@/components/StaticPage/StaticPageLayout'
import { get_SKIP_BUILD_STATIC_PATHS, SKIP_BUILD_STATIC_PATHS } from '@/constants'
import { env } from '@/env.mjs'
import { getClient } from '@/sanity/client'
import {
  getPageDataBySlug,
  pageBySlug,
  pageStaticDataBySlug,
} from '@/sanity/queries/page/pageBySlug'
import {
  getQueryBasedOnStructure,
  mergeStructure,
} from '@/sanity/queries/page/pageCommonQueries/pageBuilderComponentsData'
import { getCommonData } from '@/sanity/services/common/getCommonData'
import { getAllPageSlugs } from '@/sanity/services/page/getAllPageSlugs'
import { getPageBySlug, getPageStructureBySlug } from '@/sanity/services/page/getPageBySlug'
import type { LayoutResolver } from '@/types/appLayout'
import { createKeyFromSlug, removePrefixSlug } from '@/utils/slug'

type SpecialPagesProps = InferGetStaticPropsType<typeof getStaticProps>

const Container = ({ data }: { data: SpecialPagesProps['data'] }) => {
  if (data.isStaticPage === true) return <StaticPageContainer htmlPage={data.html} />

  return data.slug.current === BOOKS_ABOUT_SLUG ? (
    <BooksAboutContainer data={data} />
  ) : (
    <SpecialPagesContainer data={data} />
  )
}

export default function SpecialPages(props: SpecialPagesProps) {
  const { data, draftMode, queryParams, token, layoutProps } = props

  if (draftMode)
    return data.isStaticPage ? (
      <StaticPagePreview
        layoutProps={layoutProps}
        data={data}
        seo={data.seo}
        query={pageBySlug}
        params={queryParams}
        Container={Container}
        token={token}
      />
    ) : (
      <PreviewPage
        layoutProps={layoutProps}
        data={data}
        seo={data.seo}
        query={pageBySlug}
        params={queryParams}
        Container={Container}
        token={token}
      />
    )

  return (
    <>
      <SEOComponent data={data.seo} />
      <Container data={data} />
    </>
  )
}

export const getStaticPaths = async () => {
  if (SKIP_BUILD_STATIC_PATHS !== null)
    return get_SKIP_BUILD_STATIC_PATHS([
      { slug: ['books'] },
      { slug: ['collect'] },
      { slug: ['fairs'] },
      { slug: ['prints-editions'] },
    ])
  const { client } = getClient({
    draftMode: false,
    draftViewToken: '',
    originName: 'special_page-getStaticPaths',
    filePath: __filename,
  })
  const paths = await getAllPageSlugs({ client })
  return {
    paths: paths.map((item) => ({
      params: { slug: removePrefixSlug(item.params.slug, '').split('/') },
    })),
    fallback: true,
  }
}

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const { params = {}, draftMode = false, revalidateReason } = ctx
  const currentSlug = `/${((params.slug as any) ?? [])?.join('/')}`
  const queryParams = { slug: currentSlug }
  const draftViewToken = draftMode ? env.SANITY_API_READ_TOKEN : ''
  const { client } = getClient({
    draftMode,
    draftViewToken,
    revalidateReason,
    slug: currentSlug,
    originName: 'special_page-getStaticProps',
    filePath: __filename,
  })

  const specialPageStructure = await getPageStructureBySlug(client, queryParams)
  if (!specialPageStructure) return { notFound: true }
  const { structure, isStaticPage } = specialPageStructure ?? {}
  const structureMerged = mergeStructure(structure)
  const q = getQueryBasedOnStructure({ structure: structureMerged })
  const improvedQ = isStaticPage ? pageStaticDataBySlug : getPageDataBySlug(q)

  const [{ layoutProps, dataLayerProps }, data] = await Promise.all([
    getCommonData(client, queryParams),
    getPageBySlug(client, queryParams, S3RootFolder.Desktop, improvedQ),
  ])

  if (!data) return { notFound: true }
  if (dataLayerProps) dataLayerProps.page_data.site_section = createKeyFromSlug(data.slug.current)

  return {
    props: {
      data,
      dataLayerProps,
      queryParams,
      draftMode,
      token: draftViewToken,
      layoutProps,
    },
    revalidate: env.REVALIDATE_TIME,
  }
}

const Layout: LayoutResolver<SpecialPagesProps> = (page, pageProps) =>
  pageProps.data.isStaticPage ? (
    <StaticPageLayoutShadow
      layoutData={pageProps.layoutProps.layoutData}
      pageType={pageProps.data._type}
    >
      {page}
    </StaticPageLayoutShadow>
  ) : (
    <DefaultLayout pageType={pageProps.data._type} layoutData={pageProps.layoutProps.layoutData}>
      {page}
    </DefaultLayout>
  )

SpecialPages.getLayout = Layout
