import { groq } from 'next-sanity'
import type { z } from 'zod'

import { pageComplexFields, PageComplexFieldsSchema } from './pageCommonQueries/pageComplexFields'
import { pageSimpleFields, PageSimpleFieldsSchema } from './pageCommonQueries/pageSimpleFields'

export const page = groq`
  ${pageSimpleFields}
  ${pageComplexFields}
`
export const PageSchema = PageSimpleFieldsSchema.merge(PageComplexFieldsSchema)
export type PageType = z.infer<typeof PageSchema>
