import { useRouter } from 'next/router'

import { COLLECT_BOOKS_URL } from '@/common/constants/commonCopies'
import { DzSectionMenu } from '@/components/wrappers/DzSectionMenuWrapper'

const ARTISTS_BOOKS = '/books/artists'
const BOOKS_ABOUT = '/books/about'

export const BooksSubnav = () => {
  const { asPath } = useRouter()

  return (
    <DzSectionMenu
      sections={[
        {
          text: 'Artists',
          url: ARTISTS_BOOKS,
          active: asPath.startsWith(ARTISTS_BOOKS),
        },
        {
          text: 'All Books',
          url: COLLECT_BOOKS_URL,
          active: asPath.startsWith(COLLECT_BOOKS_URL),
        },
        {
          text: 'About',
          url: BOOKS_ABOUT,
          active: asPath.startsWith(BOOKS_ABOUT),
        },
      ]}
      prefix=""
      sticky
      usePrefix
    />
  )
}
